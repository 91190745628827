import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ZedsService } from '@netfoundry-ui/feature-zeds';
import { ZedsApplication } from '@netfoundry-ui/shared/model';
import { LoggerService, ValidateService } from '@netfoundry-ui/shared/services';
import { debounce, isEmpty } from 'lodash';

@Component({
    selector: 'app-zeds-create',
    templateUrl: './zeds-create.component.html',
    styleUrls: ['./zeds-create.component.scss'],
})
export class ZedsCreateComponent {
    appName: string[] = [''];
    identityName: string[] = ['', '', ''];
    serviceName: string[] = ['', '', ''];
    isDisabled = true;
    validationErrors: any = {
        appName: { indexes: [] },
        identityName: { indexes: [] },
    };
    validate = debounce(() => {
        this.logger.info(this.appName[0]);
        this.validationErrors = {
            appName: { indexes: [] },
            identityName: { indexes: [] },
        };
        let invalid = false;
        if (!this.isValidName(this.appName[0])) {
            this.validationErrors.appName.invalid = true;
            this.validationErrors.appName.message =
                'Name must be at least 5 characters and contain only alpha-numeric and/or -_() characters';
            this.validationErrors.appName.indexes[0] = true;
            invalid = true;
        }
        let identityNameEmpty = true;
        this.identityName.forEach((name, index) => {
            if (isEmpty(name)) {
                return;
            } else {
                identityNameEmpty = false;
            }
            if (!this.isValidName(name)) {
                this.validationErrors.identityName.invalid = true;
                this.validationErrors.identityName.message =
                    'Name must be at least 5 characters and contain only alpha-numeric and/or -_() characters';
                this.validationErrors.identityName.indexes[index] = true;
                invalid = true;
            }
        });
        this.isDisabled = invalid || identityNameEmpty;
    }, 500);

    constructor(
        private logger: LoggerService,
        private validateSvc: ValidateService,
        private zeds: ZedsService,
        private router: Router
    ) {}

    build() {
        if (isEmpty(this.appName[0])) throw new Error('application name expected');
        const requestData: ZedsApplication = new ZedsApplication();
        requestData.name = this.appName[0];
        this.identityName.forEach((i) => {
            if (i) {
                requestData.endpoints.push(i);
            }
        });
        this.serviceName.forEach((s) => {
            if (s) {
                requestData.services.push(s);
            }
        });

        try {
            this.zeds.createApplication(requestData);
            this.router.navigate(['/manage'], { queryParams: { showTourGuide: 'true' } });
        } catch (e) {
            this.logger.error(e);
            throw e;
        }
    }

    private isValidName(value: string) {
        return this.validateSvc.isValidZitiName(value);
    }
}
