import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SampleServiceComponent } from '../sample-service/sample-service.component';

@Component({
    selector: 'app-preconfig-box',
    templateUrl: './preconfig-box.component.html',
    styleUrls: ['./preconfig-box.component.scss'],
})
export class PreconfigBoxComponent {
    @Input() title = '';
    @Input() subtitle = '';
    @Input() services: any[] = [];
    @Input() noAppSelected = false;

    @Output() serviceSelected = new EventEmitter<any>();

    constructor(private dialog: MatDialog) {}

    onChange(service: any) {
        const dialogRef = this.dialog.open(SampleServiceComponent, {
            width: '600px',
            height: '800px',
            data: { name: service.name, icon: service.icon },
        });

        dialogRef.afterClosed().subscribe(() => {});
    }
}
