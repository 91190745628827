<div class="titleBar">
    <div class="row">
        <div class="selection col-lg-5 col-sm-5 col-xs-9">
            <select
                (ngModelChange)="appSelected($event)"
                [compareWith]="compareApplications"
                [ngModel]="zeds.currentApplication$ | async"
            >
                <option [ngValue]="{ name: '' }">Select an Application...</option>
                <option *ngFor="let app of zeds.applications$ | async" [ngValue]="app">
                    {{ app.name }}
                </option>
            </select>
            <div (click)="addNewApp()" class="addNewButton" title="Create a New App">
                <span class="addNew"></span>
            </div>
        </div>
        <div class="centerlogo col-lg-2 col-sm-2 hidden-xs">
            <span (click)="goToLandingPage()" class="zedsLogo"><img src="/assets/images/ZedsSandboxLogo.svg" /></span>
        </div>
        <div class="rightlogo col-lg-5 col-sm-5 col-xs-3">
            <app-app-menu></app-app-menu>
        </div>
    </div>
</div>
