import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Network } from '@netfoundry-ui/shared/model';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { ZedsService } from '../zeds.service';

@Component({
    selector: 'app-config-edit-box',
    templateUrl: './config-edit-box.component.html',
    styleUrls: ['./config-edit-box.component.scss'],
})
export class ConfigEditBoxComponent implements OnInit {
    @ViewChild(JsonEditorComponent, { static: false }) editor!: JsonEditorComponent;
    @Input() fullScreen = false;
    @Input() schema;
    @Output() updateConfig = new EventEmitter<any>();
    @Output() deleteConfig = new EventEmitter<any>();
    @Output() expandToggled = new EventEmitter<any>();
    editorOptions: JsonEditorOptions = new JsonEditorOptions();

    constructor(private zedsSvc: ZedsService) {}

    _config: any;

    @Input() set config(cfg) {
        this._config = cfg ? JSON.parse(JSON.stringify(cfg)) : {};
    }

    _configType: any;

    @Input() set configType(configType) {
        this._configType = configType;
    }

    ngOnInit() {
        this.zedsSvc.configData$.subscribe((configData) => {
            if (this._config.configTypeName === configData.type) {
                this._config.data = configData?.data;
            }
        });
    }

    update() {
        this.updateConfig.emit(this._config);
    }

    delete() {
        this.deleteConfig.emit();
    }

    toggleExpand() {
        this.expandToggled.emit();
    }

    copy(item: Network) {
        navigator.clipboard.writeText(item.id);
    }

    changeData(data) {
        if (data instanceof Event) {
            return;
        }
        this._config.data = data;
    }
}
