<main [ngClass]="{ 'show-tour-guide': showTourGuide }">
    <router-outlet></router-outlet>
    <app-tour-guide *ngIf="showTourGuide"></app-tour-guide>
    <app-loader
        *ngIf="isLoading"
        [isLoading]="isLoading"
        class="load-indicator"
        id="MainConsoleDisplay"
        style="height: 100%; width: 100%"
    >
    </app-loader>
    <app-growler></app-growler>
</main>
