<div class="icon-container">
    <img
        (click)="openTourGuide()"
        class="tour-icon"
        name="tourGuide"
        src="/assets/images/tutorial.svg"
        title="Tour Guide"
    />
    <img [matMenuTriggerFor]="menu" name="rightMenu" src="/assets/images/usermenu.svg" title="Log Out" />
</div>
<mat-menu #menu="matMenu">
    <button (click)="logout()" mat-menu-item name="logout">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
    </button>
    <button (click)="showGettingStarted()" mat-menu-item name="getting-started">
        <mat-icon>getting-started</mat-icon>
        <span>Start Page</span>
    </button>
</mat-menu>
