import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallbackComponent } from '@netfoundry-ui/page/callback';
import {
    ZedsConfigComponent,
    ZedsCreateComponent,
    ZedsGettingStartedComponent,
    ZedsManageComponent,
} from '@netfoundry-ui/page/zeds';
import { Auth0Guard } from '@netfoundry-ui/shared/authorization';

const ignoreGettingStarted = localStorage.getItem('ignoreGettingStarted');

const defaultRoutes: Routes = [
    {
        path: '',
        redirectTo: ignoreGettingStarted ? '/manage' : '/getting-started',
        pathMatch: 'full',
    },
    { path: 'callback', component: CallbackComponent },
    {
        path: 'create',
        component: ZedsCreateComponent,
        canActivate: [Auth0Guard],
    },
    {
        path: 'manage',
        component: ZedsManageComponent,
        canActivate: [Auth0Guard],
    },
    {
        path: 'config',
        component: ZedsConfigComponent,
        canActivate: [Auth0Guard],
    },
    {
        path: 'getting-started',
        component: ZedsGettingStartedComponent,
        canActivate: [Auth0Guard],
    },
];

export const routes: Routes = defaultRoutes;

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(routes, { enableTracing: false })],
})
export class AppRoutingModule {}
