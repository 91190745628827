import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-service-button',
    templateUrl: './service-button.component.html',
    styleUrls: ['./service-button.component.scss'],
})
export class ServiceButtonComponent {
    @Input() service: any;
    @Input() disabled = false;
    @Output() selected = new EventEmitter<any>();

    constructor() {}

    clicked() {
        this.selected.emit(this.service);
    }
}
