<div class="editor-box">
    <app-config-type-editor-header (closeRequest)="closeRequested()"></app-config-type-editor-header>
    <div class="input-block">
        <input [(ngModel)]="name" [disabled]="isSaved" placeholder="Enter a Type Name..." required type="text" />
        <div class="error">{{ error }}</div>
    </div>
    <div class="editbox">
        <app-json-view (dataChange)="newConfig($event)" [(data)]="config"></app-json-view>
    </div>

    <app-config-type-editor-footer (saveConfig)="save()" [disableSave]="isSaved"></app-config-type-editor-footer>
</div>
