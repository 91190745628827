<div class="sample-box">
    <app-sample-service-header
        (closeRequest)="closeRequested()"
        [icon]="icon"
        [name]="name"
    ></app-sample-service-header>
    <div id="internal">
        <div *ngIf="name === 'Hello Ziti'">
            <p>This is a test to ensure your NetFoundry Zero Trust client is accessing Zero Trust Services.</p>
            <p>
                This service(hosted service) will utilize a non-public domain name, which is part of the NetFoundry Zero
                trust network.
            </p>
            <p>
                It is inaccessible via the public internet and allows access to a containerized service which serves a
                webpage showing NetFoundry Hello world splash screen.
            </p>
            <p>This also has a link to the Netfoundry Website.</p>
            <p class="mb0">
                The server is hosted locally on a NetFoundry private component, which demonstrates fully Zero Trust
                access of a private hosted web service.
            </p>
            <div class="button-box">
                <a class="outer" href="http://hello.ziti" target="_blank">
                    <div class="inner">
                        <span class="title">http://hello.ziti</span>
                    </div>
                </a>
            </div>
            <p class="last-paragraph">
                This will return information, via proxy through the NetFoundry Secured component hosting the NetFoundry
                Hello private web server. This page hosts a private web page which has a link to NetFoundry web page to
                learn more about NetFoundry Zero trust.
            </p>
        </div>
        <div *ngIf="name === 'Fireworks'">
            <p>This is a test to ensure your NetFoundry Zero Trust client is accessing Zero Trust Services.</p>
            <p>
                This service(hosted service) will utilize a non-public domain name, which is part of the NetFoundry Zero
                trust network.
            </p>
            <p>
                It is inaccessible via the public internet and allows access to a containerized service which which
                serves a webpage showing a NetFoundry fireworks display.
            </p>
            <p class="mb0">
                The server is hosted locally on a NetFoundry private component, which demonstrates fully Zero Trust
                access of a private hosted web service.
            </p>
            <div class="button-box">
                <a class="outer" href="http://fireworks.ziti" target="_blank">
                    <div class="inner">
                        <span class="title">http://fireworks.ziti</span>
                    </div>
                </a>
            </div>
            <p class="last-paragraph">
                This will return information, via proxy through the NetFoundry Secured component hosting the NetFoundry
                Fireworks private web server running in a docker container on the host VM.
            </p>
        </div>
        <div *ngIf="name === 'HTTPBin'">
            <p>This is a test to ensure your NetFoundry Zero Trust client is accessing Zero Trust Services.</p>
            <p>
                This service(hosted service) will utilize a non-public domain name, which is part of the NetFoundry Zero
                trust network.
            </p>
            <p>
                It is inaccessible via the public internet and allows access to a httpbin container service and httpbin
                web server instance.
            </p>
            <p class="mb0">
                The server is hosted locally on a NetFoundry private component, which demonstrates fully Zero Trust
                access of a private hosted web service.
            </p>
            <div class="button-box">
                <a class="outer" href="http://httpbin.ziti" target="_blank">
                    <div class="inner">
                        <span class="title">http://httpbin.ziti</span>
                    </div>
                </a>
            </div>
            <div class="sub-button-box">
                <div>
                    This will return information, via proxy through the NetFoundry Secured component hosting the httpbin
                    private web server. This page hosts an HTTP request and response service for trying out such
                    requests and seeing the API responses. You can view the samples shown on the page.
                </div>

                <div>
                    <p>Try out some of the sample API HTTP requests using the httpbin.ziti url for example:</p>

                    <ul>
                        <li>
                            <a href="http://httpbin.ziti/image" target="_blank">http://httpbin.ziti/image</a> - returns
                            a sample image
                        </li>
                        <li>
                            <a href="http://httpbin.ziti/uuid" target="_blank">http://httpbin.ziti/uuid</a> - returns a
                            UUID4 string
                        </li>
                        <li>
                            <a href="http://httpbin.ziti/xml" target="_blank">http://httpbin.ziti/xml</a> - returns a
                            sample XML document
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div *ngIf="name === 'What\'s My IP'" class="instruction">
            <p>This is a test to ensure your NetFoundry Zero Trust client is accessing Zero Trust Services.</p>
            <p>
                This service will utilize a non-public domain name, which is part of the NetFoundry Zero trust network
                (hosted service) that is inaccessible via the public internet, to proxy an internet request to a public
                ip address.
            </p>
            <p>
                This service accesses a public host that returns the IP address of the caller, which is the NetFoundry
                component hosting the service and proxying the request.
            </p>

            <div class="actions">
                Click to open page to the following address in an internet browser:

                <div class="button-box">
                    <a class="outer" href="https://whatismyip.ziti" target="_blank">
                        <div class="inner">
                            <span class="title">https://whatismyip.ziti</span>
                        </div>
                    </a>
                    <a class="outer" href="http://whatismyip.ziti" target="_blank">
                        <div class="inner">
                            <span class="title">http://whatismyip.ziti</span>
                        </div>
                    </a>
                </div>

                <div>
                    This will return the IP address of the NetFoundry Secured component hosting the service which is
                    defined to forward the request to <em>https://eth0.me</em> or <em>http://eth0.me</em> on the
                    internet. It makes an outbound request and receives back its IP address.
                </div>
            </div>
        </div>
    </div>
</div>
