export class ZedsApplication {
    name = '';
    endpoints: any[];
    services: any[];

    constructor() {
        this.endpoints = [];
        this.services = [];
    }
}
