import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Environment, ENVIRONMENT, ZedsApplication } from '@netfoundry-ui/shared/model';
import { LoggerService } from '@netfoundry-ui/shared/services';
import { saveAs } from 'file-saver';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { throwError as observableThrowError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({ providedIn: 'root' })
export class ZedsService {
    lastErrorSource = new Subject<HttpErrorResponse>();
    lastError = this.lastErrorSource.asObservable();
    applications$ = new BehaviorSubject<ZedsApplication[]>([]);
    currentApplication$ = new BehaviorSubject<ZedsApplication>(new ZedsApplication());
    configTypes$ = new BehaviorSubject<string[]>([]);
    currentService$ = new BehaviorSubject({ name: '', configs: [] });
    currentAppName: string;
    isLoading$ = new BehaviorSubject<boolean>(false);
    configData$ = new BehaviorSubject<any>({});
    isAuthenticated$: Observable<boolean>;
    authenticated = false;

    constructor(
        private logger: LoggerService,
        private http: HttpClient,
        private router: Router,
        private auth0Angular: AuthService,
        @Inject(ENVIRONMENT) private env: Environment
    ) {
        this.currentAppName = localStorage.getItem('lastAppName');
        this.isAuthenticated$ = auth0Angular.isAuthenticated$;
        this.isAuthenticated$?.subscribe(async (result) => {
            this.authenticated = result;
            if (this.authenticated) {
                this.getApplications();
            }
        });
    }

    clearApp() {
        this.currentAppName = '';
        this.currentApplication$.next(new ZedsApplication());
        localStorage.setItem('lastAppName', this.currentAppName);
    }

    selectApp(name: any) {
        if (name === 'add') {
            const queryParams: any = { showTourGuide: 'true' };
            this.router.navigate(['/create'], { queryParams: queryParams });
        } else {
            this.getApplication(name);
        }
    }

    async createApplication(app: ZedsApplication): Promise<void> {
        const fullpath = `${this.env.apiUrl}/applications/${encodeURIComponent(app.name)}`;
        this.appLoading(true);
        return await this.http
            .post(fullpath, app)
            .pipe(catchError((error) => this.handleError(this, error)))
            .toPromise()
            .then(() => {
                this.appLoading(false);
                this.logger.info(`app created ${app.name}`);
                this.currentAppName = app.name;
                this.getApplications();
            });
    }

    async getApplications(): Promise<void> {
        const fullpath = `${this.env.apiUrl}/applications`;
        this.appLoading(true);
        await this.http
            .get(fullpath)
            .pipe(catchError((error) => this.handleError(this, error)))
            .subscribe((data: ZedsApplication[]) => {
                this.appLoading(false);
                this.logger.debug(JSON.stringify(data, null, 4));
                this.applications$.next(data ?? []);

                if (this.currentAppName) {
                    this.getApplication(this.currentAppName);
                } else if (data.length === 1) {
                    this.getApplication(data[0].name);
                }
                if (data.length === 0) {
                    this.router.navigate(['/create']);
                }
            });
    }

    setConfigData(configData) {
        this.configData$.next(configData);
    }

    getApplication(name: string): void {
        const fullpath = `${this.env.apiUrl}/applications/${encodeURIComponent(name)}`;
        this.http
            .get(fullpath)
            .pipe(catchError((error) => this.handleError(this, error)))
            .subscribe((data: ZedsApplication) => {
                this.logger.debug(JSON.stringify(data, null, 4));
                this.currentAppName = data.name;
                this.currentApplication$.next(data);
                localStorage.setItem('lastAppName', this.currentAppName);

                this.getConfigTypes();
            });
    }

    deleteService(serviceName: string): void {
        const fullpath = `${this.env.apiUrl}/applications/${encodeURIComponent(
            this.currentAppName
        )}/services/${encodeURIComponent(serviceName)}`;
        this.http
            .delete(fullpath)
            .pipe(catchError((error) => this.handleError(this, error)))
            .subscribe(() => {
                this.getApplication(this.currentAppName);
            });
    }

    editService(svc: any) {
        const fullpath = `${this.env.apiUrl}/applications/${encodeURIComponent(
            this.currentAppName
        )}/services/${encodeURIComponent(svc.name)}`;
        this.appLoading(true);
        this.http
            .get(fullpath)
            .pipe(catchError((error) => this.handleError(this, error)))
            .subscribe((data: { name: ''; configs: [] }) => {
                this.appLoading(false);
                this.logger.debug(JSON.stringify(data, null, 4));
                this.currentService$.next(data);
                this.router.navigate(['config'], { queryParams: { showTourGuide: 'true' } });
            });
    }

    updateService(service: any): void {
        const fullpath = `${this.env.apiUrl}/applications/${encodeURIComponent(
            this.currentAppName
        )}/services/${encodeURIComponent(service.name)}`;
        this.appLoading(true);
        this.http
            .put(fullpath, service)
            .pipe(catchError((error) => this.handleError(this, error)))
            .toPromise()
            .then((svc: any) => {
                this.appLoading(false);
                this.currentService$.next(svc);
            });
    }

    createService(serviceName: string): void {
        const fullpath = `${this.env.apiUrl}/applications/${encodeURIComponent(
            this.currentAppName
        )}/services/${encodeURIComponent(serviceName)}`;
        this.http
            .post(fullpath, {})
            .pipe(catchError((error) => this.handleError(this, error)))
            .subscribe((data: any) => {
                this.currentService$.next(data);
                this.getApplication(this.currentAppName);
            });
    }

    createConfigType(configType: any): Promise<void> {
        const fullpath = `${this.env.apiUrl}/applications/${encodeURIComponent(
            this.currentAppName
        )}/config-types/${encodeURIComponent(configType.name)}`;
        return this.http
            .post(fullpath, configType)
            .toPromise()
            .then(() => {
                this.getConfigTypes();
            })
            .catch((error) => {
                this.handleError(this, error);
                throw error;
            });
    }

    downloadToken(e: any) {
        const fullpath = `${this.env.apiUrl}/applications/${encodeURIComponent(
            this.currentAppName
        )}/endpoints/${encodeURIComponent(e.name)}/jwt`;
        this.http
            .get(fullpath, { responseType: 'blob' as const })
            .pipe(catchError((error) => this.handleError(this, error)))
            .subscribe((contents: Blob) => {
                if (contents) {
                    saveAs(contents, `${e.name}.jwt`);
                }
            });
    }

    deleteIdentity(endpointName: string): void {
        const fullpath = `${this.env.apiUrl}/applications/${encodeURIComponent(
            this.currentAppName
        )}/endpoints/${encodeURIComponent(endpointName)}`;
        this.http
            .delete(fullpath)
            .pipe(catchError((error) => this.handleError(this, error)))
            .subscribe(() => {
                this.getApplication(this.currentAppName);
            });
    }

    createIdentity(appName: string, endpointName: string): void {
        const fullpath = `${this.env.apiUrl}/applications/${encodeURIComponent(appName)}/endpoints/${encodeURIComponent(
            endpointName
        )}`;
        this.http
            .post(fullpath, {})
            .pipe(catchError((error) => this.handleError(this, error)))
            .subscribe(() => {
                this.getApplication(appName);
            });
    }

    getConfigTypes(): void {
        if (this.currentAppName) {
            const fullpath = `${this.env.apiUrl}/applications/${encodeURIComponent(this.currentAppName)}/config-types`;
            this.http
                .get(fullpath)
                .pipe(catchError((error) => this.handleError(this, error)))
                .subscribe((data: string[]) => {
                    this.logger.debug(JSON.stringify(data, null, 4));
                    this.configTypes$.next(data);
                });
        }
    }

    appLoading(isLoading) {
        this.isLoading$.next(isLoading);
    }

    updateConfig(service: any, cfg: any) {
        let isPresent = false;
        if (service.configs) {
            const cfgs = service.configs.map((c) => {
                if (c.configTypeName === cfg.configTypeName) {
                    c = JSON.parse(JSON.stringify(cfg));
                    isPresent = true;
                }
                return c;
            });
            service.configs = cfgs;
        }
        if (!isPresent) {
            if (!service.configs) service.configs = [];
            service.configs.push(JSON.parse(JSON.stringify(cfg)));
        }
        this.updateService(service);
    }

    protected handleError(scope: any, error: HttpErrorResponse) {
        this.appLoading(false);
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            this.logger.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            this.logger.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
        }
        scope.lastErrorSource.next(error);
        return observableThrowError(error);
    }
}
