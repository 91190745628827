import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LoggerService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-collector-box',
    templateUrl: './collector-box.component.html',
    styleUrls: ['./collector-box.component.scss'],
})
export class CollectorBoxComponent {
    @Input() title = '';
    @Input() subtitle = '';
    @Input() titleIcon = '';
    @Input() stepNbr = 0;
    @Input() placeholderText = '';
    @Input() allowAdd = false;
    @Input() invalid = false;
    @Input() validationErrors = {
        invalid: false,
        message: '',
        indexes: [],
    };
    @Input() data: string[] = [];

    @Output() dataChange = new EventEmitter<any>();

    constructor(private logger: LoggerService) {}

    onAdd() {
        this.data.push('');
        this.dataChange.emit(this.data);
    }

    onChange() {
        this.dataChange.emit(this.data);
    }

    indexTrackBy(index: number, val: string): any {
        return index;
    }
}
