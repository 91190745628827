<div class="page">
    <div class="create-container">
        <app-getting-started-title-bar></app-getting-started-title-bar>
        <div class="start-menu-container">
            <div class="start-menu-header">
                <div class="header-icon">
                    <div class="icon ziggy-wave"></div>
                </div>
                <div class="header-title">Your Quick Start Guide</div>
                <div class="header-details">
                    <div class="detail-meet-ziggy">Meet Ziggy from OpenZiti</div>
                    <div class="detail-congrats">Congrats! You're up and running. Now What?</div>
                </div>
            </div>
            <div class="start-menu-list-container">
                <app-start-menu-item
                    (itemSelected)="item.itemSelected($event)"
                    *ngFor="let item of menuItems"
                    [buttonText]="item.buttonText"
                    [iconClass]="item.iconClass"
                    [title]="item.title"
                >
                </app-start-menu-item>
            </div>
            <div class="toggle-container">
                <div class="hide-start-page-toggle">
                    <span class="toggle-text">Hide this page</span>
                    <div (click)="toggleHideStartPage()" [ngClass]="{ on: !showStartPage }" class="toggle">
                        <span [hidden]="showStartPage" style="margin-right: 15px">ON</span>
                        <span [hidden]="!showStartPage" style="margin-left: 15px">OFF</span>
                        <div class="switch"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
