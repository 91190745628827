import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ZedsService } from '../zeds.service';

@Component({
    selector: 'app-config-type-editor',
    templateUrl: './config-type-editor.component.html',
    styleUrls: ['./config-type-editor.component.scss'],
})
export class ConfigTypeEditorComponent implements OnDestroy {
    isSaved = false;
    isDisabled = true;
    name = '';
    config: any = {};
    _config: any;
    error = '';
    subscription = new Subscription();

    constructor(private zeds: ZedsService, private matDialogRef: MatDialogRef<any>) {
        this.subscription.add(
            zeds.lastError.subscribe((err) => {
                if (err.status) {
                    this.error = err.statusText + ' ' + err.status;
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    closeRequested() {
        this.matDialogRef.close();
    }

    newConfig(data) {
        this._config = data;
        this.error = '';
    }

    save() {
        this.error = '';
        if (this.name && this._config && !this.isSaved) {
            this.zeds
                .createConfigType({ ...this._config, name: this.name })
                .then(() => {
                    this.isSaved = true;
                    this.closeRequested();
                })
                .catch((e) => {
                    //this.error = e.message;
                });
        }
    }
}
