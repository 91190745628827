<div class="boxHeader">
    <div (click)="deleteRequested()" *ngIf="!fullScreen" class="close"></div>
    <img class="mb8" src="/assets/images/Configs.svg" />
    <div style="padding-left: 0.5em; vertical-align: middle; line-height: 1.5em; font-size: 1.5em; font-weight: bold">
        {{ configName }}
    </div>
    <div class="learn">
        <a href="https://openziti.github.io/ziti/config-store/managing.html" target="_blank">Learn More</a> about config
        types
    </div>
</div>
