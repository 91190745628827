<div class="control-box">
    <app-config-edit-header
        (delete)="delete()"
        [configName]="_config?.configTypeName"
        [fullScreen]="fullScreen"
    ></app-config-edit-header>
    <div class="editbox">
        <app-json-view
            #editor
            (jsonChange)="changeData($event)"
            [data]="_config.data"
            [schema]="schema"
            name="jsonView"
            ngDefaultControl
        >
        </app-json-view>
    </div>
    <app-config-edit-footer
        (expandToggled)="toggleExpand()"
        (updateConfig)="update()"
        [fullConfigName]="_config?.fullName"
        [fullConfigTypeName]="_configType?.fullName"
        [fullScreen]="fullScreen"
    ></app-config-edit-footer>
</div>
