import { Component } from '@angular/core';
import { ZedsService } from '@netfoundry-ui/feature-zeds';
import { ZedsApplication } from '@netfoundry-ui/shared/model';

@Component({
    selector: 'app-getting-started-title-bar',
    templateUrl: './getting-started-title-bar.component.html',
    styleUrls: ['./getting-started-title-bar.component.scss'],
})
export class GettingStartedTitleBarComponent {
    constructor(public zeds: ZedsService) {}

    appSelected(app: any) {
        if (app.name === '') {
            this.zeds.clearApp();
        } else {
            this.zeds.selectApp(app.name);
        }
    }

    compareApplications(a1: ZedsApplication, a2: ZedsApplication) {
        return a1 && a2 && a1.name === a2.name;
    }

    addNewApp() {
        this.zeds.selectApp('add');
    }
}
