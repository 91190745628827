import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-config-edit-footer',
    templateUrl: './config-edit-footer.component.html',
    styleUrls: ['./config-edit-footer.component.scss'],
})
export class ConfigEditFooterComponent {
    @Output() updateConfig = new EventEmitter<void>();
    @Output() expandToggled = new EventEmitter<void>();

    @Input() fullScreen = false;
    @Input() fullConfigName = '';
    @Input() fullConfigTypeName = '';

    constructor() {}

    update() {
        this.updateConfig.emit();
    }

    toggleExpand() {
        this.expandToggled.emit();
    }

    copy(value: string) {
        navigator.clipboard.writeText(value);
    }
}
