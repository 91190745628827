import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { take, tap } from 'rxjs/operators';
import { Auth0Service } from './auth0.service';

@Injectable({ providedIn: 'root' })
export class Auth0Guard {
    constructor(private auth: Auth0Service, private router: Router) {}

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canLoad(route, segments) {
        return this.auth.isAuthenticated$.pipe(take(1));
    }

    canActivate(next, state) {
        return this.redirectIfUnauthenticated(state);
    }

    canActivateChild(childRoute, state) {
        return this.redirectIfUnauthenticated(state);
    }

    private redirectIfUnauthenticated(state) {
        return this.auth.isAuthenticated$.pipe(
            tap((loggedIn) => {
                if (!loggedIn) {
                    this.auth.loginWithRedirect({
                        appState: { target: state.url },
                    });
                }
            })
        );
    }
}
