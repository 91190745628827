import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';

@Component({
    selector: 'app-manage-action-box',
    templateUrl: './manage-action-box.component.html',
    styleUrls: ['./manage-action-box.component.scss'],
})
export class ManageActionBoxComponent implements OnInit {
    @Input() learnmore = '';
    @Input() title = '';
    @Input() subtitle = '';
    @Input() iconSrc = '';
    @Input() placeholderText = '';
    @Input() allowAdd = false;
    @Input() noAppSelected = false;
    @Input() isShowStatus = false;
    @Input() showCopyName = false;
    @Output() createClicked = new EventEmitter<any>();
    @Output() tokenClicked = new EventEmitter<any>();
    @Output() editClicked = new EventEmitter<any>();
    @Output() deleteClicked = new EventEmitter<any>();
    isCreate = false;
    isToken = false;
    isEdit = false;
    isDelete = false;

    constructor(private growlerService: GrowlerService) {}

    _data: any[] = [];

    @Input() set data(data: any[]) {
        this._data = data;
    }

    ngOnInit(): void {
        this.isCreate = this.createClicked.observers.length > 0;
        this.isToken = this.tokenClicked.observers.length > 0;
        this.isEdit = this.editClicked.observers.length > 0;
        this.isDelete = this.deleteClicked.observers.length > 0;
    }

    create(obj: any) {
        console.log(obj);
        this.createClicked.emit(obj);
    }

    token(obj: string) {
        this.tokenClicked.emit(obj);
    }

    edit(obj: string) {
        this.editClicked.emit(obj);
    }

    delete(obj: string) {
        this.deleteClicked.emit(obj);
    }

    onAdd() {
        if (this.noAppSelected) {
            return;
        }
        this._data.push({ name: '' });
    }

    indexTrackBy(index: number, val: string): any {
        return index;
    }

    onChange(name, idx) {
        console.log(name);
        this._data[idx].name = name;
    }

    copyFullName(item) {
        navigator.clipboard.writeText(item.fullName);
        this.growlerService.show(
            new GrowlerData('success', 'Copy Success', 'Service ID Copied', 'Full service ID copied to clipboard.')
        );
    }
}
