import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-config-type-editor-header',
    templateUrl: './config-type-editor-header.component.html',
    styleUrls: ['./config-type-editor-header.component.scss'],
})
export class ConfigTypeEditorHeaderComponent {
    @Output() closeRequest = new EventEmitter<void>();

    constructor() {}

    closeRequested() {
        this.closeRequest.emit();
    }
}
