import { OverlayModule } from '@angular/cdk/overlay';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { SimpleState } from '@netfoundry-ui/data-access/simple-store';
import {
    Auth0Service,
    AUTHENTICATION,
    IAM_CONFIGURATION,
    SharedAuthorizationModule,
} from '@netfoundry-ui/shared/authorization';
import { _VERSION, Attribute, EndpointV2, ENVIRONMENT, NetworkV2 } from '@netfoundry-ui/shared/model';
import { HTTP_CLIENT, SharedServicesModule } from '@netfoundry-ui/shared/services';
import { NgxsModule } from '@ngxs/store';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FeatureZedsModule } from '@netfoundry-ui/feature-zeds';
import { UiLoaderModule } from '@netfoundry-ui/ui/loader';
import { SharedGrowlerModule } from '@netfoundry-ui/shared/growler';
import { NgxHateoasClientConfigurationService } from '@lagoshny/ngx-hateoas-client';
import { VERSION } from '../environments/version';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

export const identityConfig = {};
export const apiConfig = {};

@NgModule({
    declarations: [AppComponent],
    imports: [
        CommonModule,
        FormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        CdkScrollableModule,
        OverlayModule,
        MatMenuModule,
        MatTooltipModule,
        RouterModule,
        AppRoutingModule,
        AuthModule.forRoot({
            clientId: environment.authconfig.clientID,
            domain: environment.authconfig.domain,
            useRefreshTokens: true,
            httpInterceptor: {
                allowedList: ['http://*', 'https://*'],
            },
        }),
        NgxsModule.forRoot([SimpleState]),
        LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.ERROR }),
        SharedAuthorizationModule,
        SharedServicesModule,
        FeatureZedsModule,
        CommonModule,
        UiLoaderModule,
        SharedGrowlerModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
        { provide: ENVIRONMENT, useValue: environment },
        { provide: _VERSION, useValue: VERSION },
        { provide: AUTHENTICATION, useClass: Auth0Service },
        { provide: IAM_CONFIGURATION, useValue: identityConfig },
        { provide: HTTP_CLIENT, useClass: HttpClient },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(hateoasConfig: NgxHateoasClientConfigurationService) {
        hateoasConfig.configure({
            http: {
                rootUrl: environment.apiUrl,
            },
            logs: { verboseLogs: false },
            useTypes: {
                resources: [NetworkV2, Attribute, EndpointV2],
            },
            halFormat: {
                collections: {
                    embeddedOptional: true,
                },
            },
            cache: { enabled: true, lifeTime: 200 },
            isProduction: environment.production,
            pagination: {
                defaultPage: {
                    page: 0,
                    size: 50,
                },
            },
        });
    }
}
