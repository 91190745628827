import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-sample-service-header',
    templateUrl: './sample-service-header.component.html',
    styleUrls: ['./sample-service-header.component.scss'],
})
export class SampleServiceHeaderComponent {
    @Input() icon: string;
    @Input() name: string;
    @Output() closeRequest = new EventEmitter<void>();

    constructor() {}

    closeRequested() {
        this.closeRequest.emit();
    }
}
