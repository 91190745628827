<div class="titleBar">
    <div class="row">
        <div class="selection col-lg-5 col-sm-5 col-xs-9"></div>
        <div class="centerlogo col-lg-2 col-sm-2 hidden-xs">
            <span class="zedsLogo"><img src="/assets/images/ZedsSandboxLogo.svg" /></span>
        </div>
        <div class="rightlogo col-lg-5 col-sm-5 col-xs-3">
            <app-app-menu></app-app-menu>
        </div>
    </div>
</div>
