import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-action-button',
    templateUrl: './action-button.component.html',
    styleUrls: ['./action-button.component.scss'],
})
export class ActionButtonComponent {
    @Input() loading: boolean;
    @Input() icon: any;
    @Output() selected = new EventEmitter<void>();

    constructor() {}

    _customStyle = '';

    @Input() set customStyle(customStyle: any) {
        this._customStyle = customStyle;
    }

    clicked() {
        this.selected.emit();
    }
}
