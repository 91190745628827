<div class="titleBar">
    <div class="col-sm-3 col-xs-6 leftlogo">
        <span class="zedsLogo"><img src="/assets/images/ZedsSandboxLogo.svg" /></span>
        <span class="zedsTitle">Ziti Edge Developer Sandbox</span>
    </div>
    <div class="col-sm-6 hidden-xs titlebox">
        <span class="titleboxLogo"><img src="/assets/images/ZiggyHello.svg" /></span>
        <div class="titleboxTitle">
            Welcome to Your Sandbox<br />
            Let's Create Your App
        </div>
    </div>
    <div class="col-sm-3 col-xs-6 rightlogo">
        <app-app-menu></app-app-menu>
    </div>
</div>
