import { NetworkGroup, NetworkV2, Tenant } from '@netfoundry-ui/shared/model';

export class UpdateIsLogin {
    static readonly type = '[LOGIN] Update';

    constructor(public payload: boolean) {}
}

export class UpdateCurrentOrganization {
    static readonly type = '[ORGANIZATION] Update';

    constructor(public payload: Tenant) {}
}

export class UpdateCurrentNetworkGroup {
    static readonly type = '[NETWORKGROUP] Update';

    constructor(public payload: NetworkGroup) {}
}

export class UpdateCurrentNetwork {
    static readonly type = '[NETWORK] Update';

    constructor(public payload: NetworkV2) {}
}
