<div class="page">
    <div class="create-container">
        <app-manage-app-title-bar></app-manage-app-title-bar>
        <div class="container scrollbox">
            <div class="row">
                <div class="col-lg-6 col-sm-12">
                    <app-manage-action-box
                        (createClicked)="onCreateIdentity($event)"
                        (deleteClicked)="onDeleteIdentity($event)"
                        (tokenClicked)="onToken($event)"
                        [allowAdd]="true"
                        [data]="endpoints"
                        [isShowStatus]="true"
                        [learnmore]="'https://openziti.github.io/ziti/identities/enrolling.html'"
                        [noAppSelected]="noAppSelected"
                        [placeholderText]="'Enter new identity name...'"
                        [subtitle]="'Use tokens to register identities and connect to your services'"
                        [title]="'Manage Identities'"
                        class="manageIdentitites"
                        iconSrc="./assets/images/EndpointsIcon.svg"
                    ></app-manage-action-box>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <app-manage-action-box
                        (createClicked)="onCreateService($event)"
                        (deleteClicked)="onDeleteService($event)"
                        (editClicked)="onEditService($event)"
                        [allowAdd]="true"
                        [data]="services"
                        [learnmore]="'https://openziti.github.io/ziti/services/overview.html?tabs=create-service-ui'"
                        [noAppSelected]="noAppSelected"
                        [placeholderText]="'Enter new service name...'"
                        [showCopyName]="true"
                        [subtitle]="'Edit Service Configurations or Delete'"
                        [title]="'Manage Services'"
                        class="manageServices"
                        iconSrc="./assets/images/CustomerHosted.svg"
                    ></app-manage-action-box>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-lg-offset-3 col-sm-12">
                        <app-preconfig-box
                            (serviceSelected)="preconfigure($event)"
                            [noAppSelected]="noAppSelected"
                            [services]="preconfigs"
                            [subtitle]="'Accessed via Client Identity. Tap below to test.'"
                            [title]="'Preconfigured Test Services'"
                            class="preconfigured"
                        ></app-preconfig-box>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
