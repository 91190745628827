import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-config-type-editor-footer',
    templateUrl: './config-type-editor-footer.component.html',
    styleUrls: ['./config-type-editor-footer.component.scss'],
})
export class ConfigTypeEditorFooterComponent {
    @Input() disableSave = false;
    @Output() saveConfig = new EventEmitter<void>();

    constructor(private dialogRef: MatDialogRef<any>) {}

    save() {
        this.saveConfig.emit();
    }

    expand() {
        this.dialogRef.updateSize('100%', '100%');
    }
}
