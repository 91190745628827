import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
    selector: 'app-zeds-getting-started',
    templateUrl: './zeds-getting-started.component.html',
    styleUrls: ['./zeds-getting-started.component.scss'],
})
export class ZedsGettingStartedComponent {
    showStartPage = true;
    userPreferences: any = {};
    dialogRef: any;
    menuItems = [
        {
            title: 'Open the App Creation Guide',
            buttonText: 'TAKE THE GUIDED TOUR',
            iconClass: 'take-console-tour',
            itemSelected: this.showTourGuide.bind(this),
        },
        {
            title: 'Read the ZEDS Documentation',
            buttonText: 'READ NOW',
            iconClass: 'getting-started',
            itemSelected: this.openGetStartedDocs.bind(this),
        },
        {
            title: 'Skip the Guide and Start Building',
            buttonText: 'Build Your App',
            iconClass: 'build-your-app',
            itemSelected: this.goToCreatePage.bind(this),
        },
        {
            title: 'Manage Applications',
            buttonText: 'Manage Apps',
            iconClass: 'new-network',
            itemSelected: this.goToManagePage.bind(this),
        },
    ];

    constructor(public dialogForm: MatDialog, private router: Router) {
        const ignoreGettingStarted = localStorage.getItem('ignoreGettingStarted');
        this.showStartPage = ignoreGettingStarted !== 'true';
    }

    toggleHideStartPage() {
        this.showStartPage = !this.showStartPage;
        if (!this.showStartPage) {
            localStorage.setItem('ignoreGettingStarted', 'true');
        } else {
            localStorage.removeItem('ignoreGettingStarted');
        }
    }

    openGetStartedDocs() {
        window.open('https://openziti.github.io/index.html');
    }

    openProductTutorials() {
        window.open('https://www.youtube.com/playlist?list=PLO27BWIOqVnYh1mkkJphjXTApS1gOv3r0');
    }

    openSupportDetails() {
        window.open('https://support.netfoundry.io/hc/en-us/articles/360019471912-Contact-NetFoundry-Support');
    }

    openNetworkCreationDialog() {}

    showTourGuide() {
        this.router.navigate(['/create'], {
            queryParams: {
                showTourGuide: 'true',
                startTour: 'true',
            },
        });
    }

    goToCreatePage() {
        this.router.navigate(['/create']);
    }

    goToManagePage() {
        this.router.navigate(['/manage']);
    }
}
