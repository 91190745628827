import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ZedsService } from '@netfoundry-ui/feature-zeds';
import { ZedsApplication } from '@netfoundry-ui/shared/model';
import { LoggerService, ValidateService } from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';
import { isEmpty } from 'lodash';

@Component({
    selector: 'app-zeds-manage',
    templateUrl: './zeds-manage.component.html',
    styleUrls: ['./zeds-manage.component.scss'],
})
export class ZedsManageComponent implements OnInit, OnDestroy {
    app: ZedsApplication = new ZedsApplication();
    endpoints: any[] = [];
    services: any[] = [];
    subscription = new Subscription();
    allowAdd = false;
    noAppSelected = false;

    preconfigs: any[] = [
        { name: 'Hello Ziti', icon: './assets/images/ZiggyHello.svg' },
        { name: 'Fireworks', icon: './assets/images/Fireworks.svg' },
        { name: 'HTTPBin', icon: './assets/images/HTTPBin.svg' },
        { name: "What's My IP", icon: './assets/images/IP.svg' },
    ];

    constructor(
        private logger: LoggerService,
        private validateSvc: ValidateService,
        private zeds: ZedsService,
        private router: Router,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.subscription.add(
            this.zeds.currentApplication$.subscribe((data) => {
                this.app = JSON.parse(JSON.stringify(data));
                if (isEmpty(this.app?.name)) {
                    this.noAppSelected = true;
                    this.endpoints = [];
                    this.services = [];
                    return;
                }
                this.endpoints = [...this.app.endpoints];
                this.services = [...this.app.services];
                this.noAppSelected = false;
            })
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onCreateIdentity(identity) {
        this.itemLoading(this.endpoints, identity.name, 'creating');
        this.zeds.createIdentity(this.app.name, identity.name);
    }

    onDeleteIdentity(identity) {
        this.deleteIdentity(identity.name);
    }

    deleteIdentity(identityName: string) {
        this.itemLoading(this.endpoints, identityName, 'deleting');
        this.zeds.deleteIdentity(identityName);
    }

    onToken(e) {
        this.zeds.downloadToken(e);
    }

    onCreateService(svc) {
        this.itemLoading(this.services, svc.name, 'creating');
        this.zeds.createService(svc.name);
    }

    onEditService(svc) {
        this.zeds.editService(svc);
    }

    onDeleteService(svc) {
        this.itemLoading(this.services, svc.name, 'deleting');
        this.zeds.deleteService(svc.name);
    }

    itemLoading(items, itemName, action) {
        items.forEach((item) => {
            if (item.name === itemName) {
                item.action = action;
            }
        });
    }

    preconfigure(preconfig: any) {}
}
