import { Component, OnInit } from '@angular/core';
import { ZedsService } from '@netfoundry-ui/feature-zeds';
import { ZedsApplication } from '@netfoundry-ui/shared/model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { delay } from 'lodash';

@Component({
    selector: 'app-manage-app-title-bar',
    templateUrl: './manage-app-title-bar.component.html',
    styleUrls: ['./manage-app-title-bar.component.scss'],
})
export class ManageAppTitleBarComponent implements OnInit {
    showTourGuide = false;

    constructor(public zeds: ZedsService, private route: ActivatedRoute, private router: Router) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params: Params) => {
            if (params['showTourGuide']) {
                this.showTourGuide = true;
            }
        });
    }

    appSelected(app: any) {
        if (app.name === '') {
            this.zeds.clearApp();
        } else {
            this.zeds.selectApp(app.name);
        }
    }

    compareApplications(a1: ZedsApplication, a2: ZedsApplication) {
        return a1 && a2 && a1.name === a2.name;
    }

    addNewApp() {
        this.zeds.selectApp('add');
        if (window['enjoyhint']?.trigger) {
            delay(() => {
                window['enjoyhint'].trigger('next');
            }, 200);
        }
    }

    goToLandingPage() {
        this.router.navigate(['/getting-started']);
    }

    toggleTourGuide() {
        const queryParams: any = {};
        this.showTourGuide = !this.showTourGuide;
        if (this.showTourGuide) {
            queryParams.showTourGuide = 'true';
        }

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: queryParams,
        });
    }
}
