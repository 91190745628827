import { Component, OnDestroy, OnInit } from '@angular/core';
import { ZedsService } from '@netfoundry-ui/feature-zeds';
import { LoggerService } from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';

import { forEach, isEmpty } from 'lodash';

@Component({
    selector: 'app-zeds-config',
    templateUrl: './zeds-config.component.html',
    styleUrls: ['./zeds-config.component.scss'],
})
export class ZedsConfigComponent implements OnInit, OnDestroy {
    svc: any = { name: '', fullName: '', configs: [] };
    subscription = new Subscription();
    configs = [];
    configTypes: any = {};
    fullScreenEdit = false;
    configSchema: any;

    constructor(private logger: LoggerService, public zeds: ZedsService) {}

    editSelectedConfig() {}

    ngOnInit(): void {
        this.subscription.add(
            this.zeds.currentService$.subscribe((data) => {
                this.logger.info(`editing ${JSON.stringify(data)}`);
                this.svc = data;
                if (this.svc.configs && this.svc.configs.length !== 0) {
                    if (this.svc.configs) this.configs = [...this.svc.configs];
                }
                this.syncConfigAndConfigTypes();
            })
        );
        this.subscription.add(
            this.zeds.configTypes$.subscribe((data: any) => {
                this.configTypes = {};
                data.forEach((ct) => {
                    this.configTypes[ct.name] = ct;
                });
                this.syncConfigAndConfigTypes();
            })
        );
    }

    syncConfigAndConfigTypes() {
        if (isEmpty(this.configs) || isEmpty(this.configTypes)) {
            return;
        }
        for (const config of this.configs) {
            forEach(this.configTypes, (configType) => {
                if (config.configTypeName === configType.name) {
                    config.cfg_schema = configType.schema;
                    return;
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    update(cfg: any) {
        this.zeds.updateConfig(this.svc, cfg);
    }

    addConfig(configType: any) {
        this.logger.info(`adding ${configType.name}`);
        this.configs = [...this.configs, { configTypeName: configType.name, cfg_schema: configType.schema }];
        this.configSchema = configType.schema;
    }

    expandEditor(cfg: any) {
        this.fullScreenEdit = !this.fullScreenEdit;
        this.configs.forEach((config: any) => {
            if (cfg.configTypeName === config.configTypeName) {
                cfg.hidden = false;
            } else {
                config.hidden = this.fullScreenEdit;
            }
        });
    }

    deleteConfig(cfg: any) {
        let found = false;
        for (let idx = 0; idx < this.configs.length; idx++) {
            if (this.configs[idx].configTypeName === cfg.configTypeName) {
                found = true;
                break;
            }
        }

        if (found) {
            if (this.svc.configs && this.svc.configs.length > 0) {
                this.svc.configs = this.svc.configs.filter((c) => c.configTypeName !== cfg.configTypeName);
                this.zeds.updateService(this.svc);
            }
            this.configs = this.configs.filter((c) => c.configTypeName !== cfg.configTypeName);
        } else {
            this.configs = this.configs.filter((c) => c.configTypeName !== cfg.configTypeName);
        }
    }

    copy() {
        navigator.clipboard.writeText(this.svc.fullName);
    }
}
