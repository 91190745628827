import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ZedsService } from '../zeds.service';

@Component({
    selector: 'app-sample-service',
    templateUrl: './sample-service.component.html',
    styleUrls: ['./sample-service.component.scss'],
})
export class SampleServiceComponent {
    @Input() name = '';
    @Input() icon = '';

    constructor(
        private zeds: ZedsService,
        private matDialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.name = data.name;
        this.icon = data.icon;
    }

    closeRequested() {
        this.matDialogRef.close();
    }
}
