import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-button-bar',
    templateUrl: './button-bar.component.html',
    styleUrls: ['./button-bar.component.scss'],
})
export class ButtonBarComponent {
    @Input() isDisabled = true;
    @Output() buildApp = new EventEmitter<any>();

    buttonText = 'BUILD MY APP';

    constructor() {}

    onClick() {
        this.buildApp.emit();
    }
}
