<div
    [ngClass]="{ disabled: disabled }"
    [title]="disabled ? 'Please select an application before using test services' : ''"
    class="outer"
>
    <div (click)="clicked()" class="inner">
        <img [src]="service.icon" />
    </div>
    <div class="title">{{ service.name }}</div>
</div>
