import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfigTypeEditorComponent } from '../config-type-editor/config-type-editor.component';
import { ZedsService } from '../zeds.service';

@Component({
    selector: 'app-custom-config-box',
    templateUrl: './custom-config-box.component.html',
    styleUrls: ['./custom-config-box.component.scss'],
})
export class CustomConfigBoxComponent implements OnInit, OnDestroy {
    selectedConfigType = { name: '0' };
    subscription = new Subscription();
    configTypes;
    configTypes$ = new BehaviorSubject([]);
    @Output() addConfig = new EventEmitter<any>();

    constructor(public zeds: ZedsService, private dialog: MatDialog) {}

    _selectedConfigs = [];

    @Input() set selectedConfigs(selectedCfgs) {
        this._selectedConfigs = selectedCfgs ? [...selectedCfgs] : [];
        this.filterList();
    }

    cfgSelected(cfgType: any) {
        this.selectedConfigType = cfgType;
    }

    compareTypes(a1: any, a2: any) {
        return a1 && a2 && a1.name === a2.name;
    }

    add() {
        if (this.selectedConfigType && this.selectedConfigType.name !== '0') {
            this.addConfig.emit(this.selectedConfigType);
        }
    }

    ngOnInit(): void {
        // only need this once since app won't change
        // while this component is active
        // but custom config type could be added
        this.subscription.add(
            this.zeds.configTypes$.subscribe((data: any) => {
                this.configTypes = data;
                this.filterList();
            })
        );
    }

    filterList() {
        if (this.configTypes && this._selectedConfigs) {
            const svcConfigs =
                this._selectedConfigs.length > 0 ? this._selectedConfigs.map((c) => c.configTypeName) : [];
            const shortList = this.configTypes.filter((ct) => !svcConfigs.includes(ct.name));
            this.configTypes$.next(shortList);
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    custom($event) {
        $event.preventDefault();
        const dialogRef = this.dialog.open(ConfigTypeEditorComponent, {
            width: '500px',
            height: '700px',
        });

        dialogRef.afterClosed().subscribe(() => {});
    }
}
