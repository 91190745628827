<div class="addtitle">Select an existing Config Type or add a new one</div>
<div class="control-box">
    <img src="/assets/images/Configs.svg" />
    <div class="configControls">
        <select (ngModelChange)="cfgSelected($event)" [compareWith]="compareTypes" [ngModel]="selectedConfigType">
            <option [ngValue]="{ name: '0' }">Select a Type...</option>
            <option *ngFor="let cfgType of configTypes$ | async" [ngValue]="cfgType">
                {{ cfgType.name }}
            </option>
        </select>
        <span class="customtext">or <a (click)="custom($event)" href="">Add Custom Config Type</a></span>
    </div>
    <button (click)="add()" [disabled]="selectedConfigType.name === '0'" class="button">Add</button>
</div>
