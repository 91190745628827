<div class="titleBar">
    <div class="row">
        <div class="selection col-lg-4 col-sm-4 col-xs-9">
            <div [routerLink]="['/manage']" class="navlink">Manage App</div>
        </div>
        <div class="centerlogo col-lg-4 col-sm-4 hidden-xs">
            <span class="zedsLogo"><img src="/assets/images/ZedsSandboxLogo.svg" /></span>
        </div>
        <div class="rightlogo col-lg-4 col-sm-4 col-xs-3">
            <app-app-menu></app-app-menu>
        </div>
    </div>
</div>
