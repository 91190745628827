<div class="page">
    <div class="create-container">
        <app-create-app-title-bar></app-create-app-title-bar>
        <div class="container scrollbox">
            <div class="row">
                <div class="col-lg-4 col-sm-12 app-name-box">
                    <app-collector-box
                        (dataChange)="validate()"
                        [(data)]="appName"
                        [placeholderText]="'Enter an App Name'"
                        [stepNbr]="1"
                        [subtitle]="'We\'ll use this to link everything together'"
                        [titleIcon]="'/assets/images/AppName.svg'"
                        [title]="'Choose an App Name'"
                        [validationErrors]="validationErrors.appName"
                        class="new-app-name"
                    ></app-collector-box>
                </div>
                <div class="col-lg-4 col-sm-12 identity-name-box">
                    <app-collector-box
                        (dataChange)="validate()"
                        [(data)]="identityName"
                        [allowAdd]="true"
                        [placeholderText]="'Identity Name {i}'"
                        [stepNbr]="2"
                        [subtitle]="'You can add more later'"
                        [titleIcon]="'/assets/images/EndpointsIcon.svg'"
                        [title]="'Enter the names of identities which should access this service'"
                        [validationErrors]="validationErrors.identityName"
                        class="new-identity-name"
                    ></app-collector-box>
                </div>
                <div class="col-lg-4 col-sm-12 service-name-box">
                    <app-collector-box
                        (dataChange)="validate()"
                        [(data)]="serviceName"
                        [allowAdd]="true"
                        [placeholderText]="'Service Name {i}'"
                        [stepNbr]="3"
                        [subtitle]="'You\'ll configure them later'"
                        [titleIcon]="'/assets/images/CustomerHosted.svg'"
                        [title]="'How many services would you like?'"
                        class="new-service-name"
                    ></app-collector-box>
                </div>
            </div>
        </div>
        <app-button-bar (buildApp)="build()" [isDisabled]="isDisabled" class="build-app-bar"></app-button-bar>
    </div>
</div>
