<div class="page">
    <div class="create-container">
        <app-config-title-bar></app-config-title-bar>
        <div [ngClass]="{ fullScreenEdit: fullScreenEdit }" class="container scrollbox">
            <div *ngIf="!fullScreenEdit" class="row">
                <div *ngIf="svc" class="configtitle">
                    {{ svc.name + (svc.name.endsWith('Service') ? '' : ' Configuration') }}
                </div>
                <div class="serviceFullName">
                    <div *ngIf="svc.fullName">
                        <strong>Service ID:</strong>
                        {{ svc.fullName }}
                    </div>
                    <img
                        (click)="copy()"
                        *ngIf="svc.fullName"
                        class="copyicon"
                        src="/assets/svgs/copy.svg"
                        style="width: 22px; height: 19px"
                        title="Click to copy full service name for use in configs and sdks [{{ svc.fullName }}]"
                    />
                </div>
            </div>
            <div class="row">
                <div *ngFor="let cfg of configs" [hidden]="cfg.hidden" class="config-container">
                    <div [ngClass]="{ 'col-lg-6': !fullScreenEdit, 'col-lg-12': fullScreenEdit }" class="col-sm-12">
                        <app-config-edit-box
                            (deleteConfig)="deleteConfig(cfg)"
                            (expandToggled)="expandEditor(cfg)"
                            (updateConfig)="update($event, cfg)"
                            [configType]="configTypes[cfg.configTypeName]"
                            [config]="cfg"
                            [fullScreen]="fullScreenEdit"
                            [schema]="cfg.cfg_schema"
                        ></app-config-edit-box>
                    </div>
                </div>
            </div>
            <div *ngIf="!fullScreenEdit" class="addCustom col-lg-6 col-sm-12">
                <app-custom-config-box
                    (addConfig)="addConfig($event)"
                    [selectedConfigs]="configs"
                ></app-custom-config-box>
            </div>
        </div>
    </div>
</div>
