import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Auth0Service } from '@netfoundry-ui/shared/authorization';
import { _VERSION, Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';
import { LoggerService } from '@netfoundry-ui/shared/services';
import LogRocket from 'logrocket';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { ZedsService } from '@netfoundry-ui/feature-zeds';
import { GrowlerComponent, GrowlerService } from '@netfoundry-ui/shared/growler';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    subscription: Subscription = new Subscription();
    localProfile: any;
    showTourGuide = false;
    step = 0;
    isLoading = false;
    growlerServiceSub: Subscription;

    @ViewChild(GrowlerComponent, { static: true }) growler: GrowlerComponent;

    constructor(
        private logger: LoggerService,
        private authSvc: Auth0Service,
        private activatedRoute: ActivatedRoute,
        private zedsService: ZedsService,
        private growlerService: GrowlerService,
        @Inject(ENVIRONMENT) private environment: Environment,
        @Inject(_VERSION) private version: any
    ) {}

    ngOnDestroy(): void {
        if (this.subscription) this.subscription.unsubscribe();
        if (this.growlerServiceSub) this.growlerServiceSub.unsubscribe();
    }

    ngOnInit(): void {
        this.initLogRocket();
        this.subscription.add(
            this.authSvc.getUserObservable().subscribe((user) => {
                if (user) {
                    if (!this.localProfile) {
                        this.localProfile = user;
                        LogRocket.identify('profile.email', {
                            email: user.email,
                        });
                    }
                    if (this.subscription) {
                        this.subscription.unsubscribe();
                    }
                }
            })
        );
        this.zedsService.isLoading$.subscribe((isLoading) => {
            this.isLoading = isLoading;
        });
        this.activatedRoute.queryParams.subscribe((params: Params) => {
            // If we have an invite key, show the splash page
            const showTour = params['showTourGuide'] === 'true';
            const startTour = params['startTour'] === 'true';
            if (showTour) {
                this.showTourGuide = true;
            } else {
                this.showTourGuide = false;
            }
        });
        this.growlerServiceSub = this.growlerService.data$.subscribe((data) => {
            this.showTheGrowler(data);
        });
    }

    toggleStep(stepNumber) {
        if (this.step === stepNumber) {
            this.step = 0;
            return;
        }
        this.step = stepNumber;
    }

    prevStep() {
        if (this.step === 0) {
            return;
        }
        this.step--;
    }

    nextStep() {
        if (this.step === 5) {
            return;
        }
        this.step++;
    }

    showTheGrowler(data) {
        this.growler.title = data.title;
        this.growler.subtitle = data.subtitle;
        this.growler.level = data.level;
        this.growler.content = data.content;
        this.growler.show = true;
        setTimeout(() => {
            this.growler.show = false;
        }, 5000);
    }

    private initLogRocket(): void {
        if (!this.environment.disableLogRocket) {
            this.logger.info('initializing logrocket');
            LogRocket.init(`pnjasd/zeds-${this.environment.domain}`, {
                release: `${this.version.version} ${this.version.buildDate} ${this.version.buildTime}`,
                shouldParseXHRBlob: true,
                browser: {
                    urlSanitizer: (url) => {
                        let sanitizedUrl = url;
                        sanitizedUrl = sanitizedUrl.replace(/access_token=([^&]*)/, 'access_token=**redacted**');
                        sanitizedUrl = sanitizedUrl.replace(/identityId=([^&]*)/, 'identityId=**redacted**');
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        sanitizedUrl = sanitizedUrl.replace(/\/client\/(.*)/i, '/client/**redacted**');
                        return sanitizedUrl;
                    },
                },
                network: {
                    requestSanitizer: (request) => {
                        if (request.headers['Authorization']) {
                            request.headers['Authorization'] = '**redacted**';
                        }
                        return request;
                    },

                    responseSanitizer: (response) => {
                        if (response.body) {
                            if (response.body['nfToken']) {
                                response.body = '**redacted**';
                            }
                        }
                        return response;
                    },
                },
            });
        }
    }
}
