import { Component } from '@angular/core';

import { cloneDeep, delay, get, isEmpty } from 'lodash';
import { ActivatedRoute, Params, Router } from '@angular/router';

import * as $ from 'jquery';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { ZedsService } from '../zeds.service';

const EnjoyHint = get(window, 'EnjoyHint', (p?: any) => {});

@Component({
    selector: 'app-tour-guide',
    templateUrl: './tour-guide.component.html',
    styleUrls: ['./tour-guide.component.scss'],
})
export class TourGuideComponent {
    currentStepId = 0;
    showRootMenu = true;
    showSubMenu = false;
    prevDisabled = false;
    nextDisabled = false;
    homeDisabled = false;
    restartDisabled = false;
    currentStep: any = {};
    currentSubStep: any = {};
    tourPaused = false;
    currentHoldCount = 0;
    actionOpen = false;
    modalOpen = false;
    enjoy_hint_options = {
        showSkip: false,
        showNext: true,
        showPrev: true,
        onEnd: this.stepEnd.bind(this),
        keyCode: '',
        top: 100,
        onSkip: () => {
            this.modalOpen = false;
        },
    };
    enjoyhint_instance = new EnjoyHint(this.enjoy_hint_options);
    enjoyhint_script_steps = [
        /*{
      event: 'new-app',
      selector: '.addNew',
      description: 'Click the "Add" button to start creating your app',
    },*/
    ];
    enjoyhintStarted = false;
    dialogRef;
    steps = [
        {
            id: 0,
            name: 'Create a New App',
            stepStart: 1,
            route: '/create',
            icon: '/assets/images/AppName.svg',
            subSteps: [
                {
                    id: 0,
                    name: 'Give Your App a Name',
                    subText: '<p>Names must contain at least 5 characters</p>',
                    event: 'new-app-name',
                    stepStart: 1,
                    validation: () => {
                        const appName = $('.new-app-name input').first().val();
                        let isValid = false;
                        if (isEmpty(appName) || appName.length < 5) {
                            $('.new-app-name input').first().addClass('invalid');
                        } else {
                            isValid = true;
                            $('.new-app-name input').first().removeClass('invalid');
                        }
                        return isValid;
                    },
                },
                {
                    id: 1,
                    name: 'Define Identities',
                    subText: `<p>Enter the name(s) for at least 1 identity to use in your application. (ie. my-desktop, my-mobile, etc...)
                              You'll use these identities to authenticate endpoints within your app's network.</p>
                              <p>You can learn more about identities <a href="https://openziti.github.io/ziti/identities/overview.html?">here</a></p>`,
                    event: 'new-identity-name',
                    stepStart: 2,
                    validation: () => {
                        const identityName = $('.new-identity-name input').first().val();
                        let isValid = false;
                        if (isEmpty(identityName) || identityName.length < 5) {
                            $('.new-identity-name input').first().addClass('invalid');
                        } else {
                            isValid = true;
                            $('.new-identity-name input').first().removeClass('invalid');
                        }
                        return isValid;
                    },
                },
                {
                    id: 2,
                    name: 'Define a Service',
                    subText: `<p>Enter the name(s) for services you would like to configure in your application.
                                 These will be used to define how the OpenZiti network should intercept and relay data across your ZeroTrust application.
                              </p>
                              <p>For this tutorial let's use "hello-ziggy-svc".</p>
                              <p>You can learn more about services <a href="https://openziti.github.io/ziti/services/overview.html?tabs=create-service-ui">here</a></p>`,
                    event: 'new-service-name',
                    stepStart: 3,
                },
                {
                    id: 3,
                    name: 'Build App',
                    subText: `<p>Click on the "Build App" button to create your new application.</p>`,
                    event: 'build-app',
                    stepStart: 4,
                },
            ],
            hints: [
                {
                    event: 'new-app-name',
                    selector: '.app-name-box',
                    description: 'Enter a name for your app',
                    onBeforeStart: () => {
                        delay(() => {
                            $('.new-app-name input').first().focus();
                        }, 500);
                    },
                },
                {
                    event: 'new-identity-name',
                    selector: '.identity-name-box',
                    description: 'Enter names for one or more identities that will use this app',
                    onBeforeStart: () => {
                        delay(() => {
                            $('.new-identity-name input').first().focus();
                        }, 500);
                    },
                },
                {
                    event: 'new-service-name',
                    selector: '.service-name-box',
                    description:
                        "Enter your service names here. If you're not sure what to name your service(s) you can skip this step for now and define a service later.",
                    onBeforeStart: () => {
                        delay(() => {
                            $('.new-service-name input').first().focus();
                        }, 500);
                    },
                },
                {
                    event: 'build-app',
                    selector: '.build-app-bar .button',
                    description: 'Click "Build My App"',
                    onBeforeStart: () => {
                        delay(() => {
                            $('.build-app-bar .button').first().focus();
                        }, 500);
                    },
                },
            ],
        },
        {
            id: 1,
            name: 'Enroll an Identity',
            route: '/manage',
            icon: './assets/images/EndpointsIcon.svg',
            subSteps: [
                {
                    id: 0,
                    name: 'Install a Ziti Edge Tunneler',
                    subText: `<p>You'll need a Ziti edge tunneler inorder to register/enroll the identities from step 1 in your network.</p>
                              <p>If you haven't already done this, you can download a tunneler for your device <a href="https://netfoundry.io/resources/support/downloads/networkversion7/#zititunnelers" target="_blank">here</a></p>
                              <p>Once installed make sure it's running.</p>`,
                    pause: true,
                    action: this._showInstallerConfirm.bind(this),
                },
                {
                    id: 1,
                    name: 'Download Enrolment Token',
                    subText: `<p>Click on the "key" button for the identity you created in step #1.
                              This will download an OTT (One-Time-Token) that the Ziti Edge tunneler will use to enroll your identity.</p>`,
                },
                {
                    id: 2,
                    name: 'Enroll Identity',
                    subText: `<p>Open Your Ziti Edge tunneler and click the "Add Identity" button.
                              Follow the steps to locate and select the enrolment token downloaded in the previous step.</p>
                              <div class="step-image-container"><img class="step-image" src="/assets/images/add-identity.png"></div>`,
                },
            ],
            hints: [
                {
                    event: 'download-token',
                    selector: '.manageIdentitites .tokenBtn-0',
                    description: 'Click here to download your enrolment token',
                    onBeforeStart: () => {
                        this.modalOpen = true;
                    },
                },
            ],
        },
        {
            id: 2,
            name: 'Access Test Services',
            icon: '/assets/images/CustomerHosted.svg',
            subSteps: [
                {
                    id: 0,
                    name: 'Click on a Sample Service',
                    subText: `<p>Now that your identity is enrolled, you should be able to access one of the ZEDS "pre-built" services by selecting one from the bottom of the page.</p>
                              <p>These services are only accessible to devices with identities in your ZEDS application and that have been enrolled with OpenZiti.</p>
                              <p>This will allow us to verify that your devices identity enrolled successfully and that your Ziti Edge Tunneler is running.</p>`,
                },
            ],
            hints: [
                {
                    event: 'sample-services',
                    selector: '.preconfigured .control-box',
                    description:
                        'Click any one of the "sample" services to verify your identity is enrolled and online',
                    onBeforeStart: () => {
                        this.modalOpen = true;
                    },
                },
            ],
            route: '/manage',
        },
        {
            id: 3,
            name: 'Create a Custom Service',
            icon: '/assets/images/Configs.svg',
            subSteps: [
                {
                    id: 0,
                    name: 'Click the Service "edit" Button',
                    subText: '<p>Click the "edit" button for the service created in step #1.</p>',
                    route: '/manage',
                },
                {
                    id: 1,
                    name: 'Define a "Host" Config',
                    subText: `<p>Configs are used to define how OpenZiti services should intercept and relay data in your application</p>
                            <p>For this tutorial we'll be using a "host.v1" and "intercept.v1" config.</p>
                            <p>You can learn more about configs <a href="https://openziti.github.io/ziti/config-store/overview.html" target="_blank">here</a></p>`,
                    hintCount: 3,
                    route: '/config',
                },
                {
                    id: 2,
                    name: 'Edit Host Config',
                    subText: `<p>Define the hostname, port, and protocol (tcp/udp) that will be used for this service.</p>
                            <p>In this tuorial we'll be using TCP to send data to/from <a>https://hello.ziggy:2020</a> </p>`,
                    route: '/config',
                    holdCount: 1,
                },
                {
                    id: 3,
                    name: 'Save Host Config',
                    subText: `<p>Save this config by clicking the "Save" button bellow the editor.</p>`,
                    route: '/config',
                },
                {
                    id: 4,
                    name: 'Define an Intercept Config',
                    subText: `<p>Select the <b>intercept.v1</b> config and click <b>Add</b></p>`,
                    route: '/config',
                },
                {
                    id: 5,
                    name: 'Edit Intercept Config',
                    subText: `<p>Define the hostname, port, and protocol (tcp/udp) that will be used for this service.</p>
                              <p>In this tuorial we'll be using TCP to send data to/from <a>https://hello.ziggy:2020</a> </p>`,
                    route: '/config',
                    holdCount: 1,
                },
                {
                    id: 6,
                    name: 'Save Intercept Config',
                    subText: `<p>Save this config by clicking the "Save" button bellow the editor.</p>`,
                    route: '/config',
                },
            ],
            hints: [
                {
                    event: 'custom-services',
                    selector: '.manageServices .editBtn-0',
                    description: 'Click the "edit service" button on one of the the service\'s created in step 2.',
                    onBeforeStart: () => {
                        this.modalOpen = true;
                    },
                },
                {
                    event: 'config-type',
                    selector: '.addCustom .control-box',
                    description: 'Choose the "host.v1" a Config Type from drop down, then click the "Add" button.',
                    onBeforeStart: () => {
                        this.modalOpen = true;
                        $('addCustom .button').off('click');
                        $('.addCustom .button').click((event) => {
                            delay(() => {
                                this.nextStep();
                            }, 100);
                        });
                    },
                },
                {
                    event: 'config-data-hostv1',
                    selector: '.config-container .control-box',
                    description: `Use this JSON editor to define your config.`,
                },
                {
                    event: 'config-data-hostv1-edit',
                    selector: '.config-container .control-box',
                    description: `For this tutorial we'll define this config for you.`,
                    onBeforeStart: () => {
                        const configData = {
                            type: 'host.v1',
                            data: {
                                address: 'hello.ziggy',
                                forwardPort: true,
                                forwardProtocol: true,
                                allowedProtocols: ['tcp'],
                                allowedPortRanges: [
                                    {
                                        low: 2020,
                                        high: 2020,
                                    },
                                ],
                            },
                        };
                        this.zedsSvc.setConfigData(configData);
                    },
                },
                {
                    event: 'save-host-config',
                    selector: '.boxFooter .button',
                    description: 'Click here to save your config',
                },
                {
                    event: 'config-type',
                    selector: '.addCustom .control-box',
                    description: 'Choose the "intercept.v1" Config Type from drop down, then click the Add button.',
                    onBeforeStart: () => {
                        $('addCustom .button').off('click');
                        $('.addCustom .button').click((event) => {
                            delay(() => {
                                this.nextStep();
                            }, 100);
                        });
                    },
                },
                {
                    event: 'config-data-interceptv1',
                    selector: '.config-container:nth-child(2) .control-box',
                    description: `Use this JSON editor to define your config.`,
                },
                {
                    event: 'config-data-interceptv1-edit',
                    selector: '.config-container:nth-child(2) .control-box',
                    description: `For this tutorial we'll define this config for you.`,
                    onBeforeStart: () => {
                        const configData = {
                            type: 'intercept.v1',
                            data: {
                                addresses: ['hello.ziggy'],
                                protocols: ['tcp'],
                                portRanges: [
                                    {
                                        low: 2020,
                                        high: 2020,
                                    },
                                ],
                            },
                        };
                        this.zedsSvc.setConfigData(configData);
                        $('.control-box .boxFooter .button').off('click');
                        $('.config-container:nth-child(2) .boxFooter .button').click((event) => {
                            delay(() => {
                                //this.zedsSvc.currentService$
                                this.nextStep();
                            }, 100);
                        });
                    },
                },
                {
                    event: 'save-intercept-config',
                    selector: '.config-container:nth-child(2) .boxFooter .button',
                    description: 'Click here to save your config',
                },
            ],
        },
        {
            id: 4,
            name: 'Run an SDK Embeded App',
            stepStart: 1,
            route: '/manage',
            icon: '/assets/images/AppName.svg',
            subSteps: [
                {
                    id: 0,
                    name: 'Create an SDK Identity',
                    subText: `<p>Create a new Identity to use in the "Hello Ziggy" OpenZiti SDK demo app.</p>`,
                    route: '/manage',
                    holdCount: 1,
                },
                {
                    id: 1,
                    name: 'Save Identity',
                    subText: `<p>Save the new Identity by clicking the checkmark button</p>`,
                    route: '/manage',
                },
                {
                    id: 2,
                    name: 'Download the Edge SDK project',
                    subText: `<p>For this next part, you'll need a copy of the <strong>Hello Ziggy</strong> OpenZiti SDK project on your local device</p>
                        <p>Included in the project bundle is a prebuilt JAR file. If you wish to build the project from source
                           follow the instructions in the project <strong>README</strong> section.</p>`,
                    route: '/manage',
                    pause: true,
                    action: () => {
                        if (this.actionOpen) {
                            return;
                        }
                        this.actionOpen = true;
                        const data = {
                            title: 'Before We Continue',
                            appendId: 'ZEDS',
                            subtitle: 'Please make sure you have the following downloaded/installed on your device:',
                            bulletList: [
                                {
                                    name: 'Checkout of the "Hello Ziggy" demo project',
                                    src: 'https://github.com/rgallettonf/ziti-sdk-jvm-zeds/tree/zeds-hello-ziggy/samples/zeds-hello-ziggy',
                                },
                                {
                                    name: 'Install of Java JDK 17 or higher',
                                    src: 'https://www.oracle.com/java/technologies/downloads/',
                                },
                            ],
                            icon: 'Confirm',
                            action: 'Done',
                        };
                        this.dialogRef = this.dialogForm.open(ConfirmComponent, {
                            data: data,
                            height: '340px',
                            width: '600px',
                            autoFocus: false,
                        });
                        this.dialogRef.afterClosed().subscribe((result) => {
                            if (result) {
                                this.actionOpen = false;
                                this.nextStep();
                            }
                        });
                    },
                },
                {
                    id: 3,
                    name: 'Configure Properties File',
                    subText: `<p>Enter details for the following properties:</p>
                        <p><strong>identity.name</strong>: Name of the Identity created in Step #1 </p>
                        <p><strong>service.id</strong>: ID of the custom service we created in the previous section. If you don't know where to find this we'll show you in the next step</p>
                        <p>Open a command window inside the <strong>/dist</strong> directory</p>`,
                    route: '/manage',
                    pause: true,
                },
                {
                    id: 4,
                    name: 'Download Enrolment Token',
                    subText: `<p>Click on the "key" button for the identity you created in step #1.</p>
                          <p>Copy this file into the "identites" folder in the Hello Ziggy project.</p>`,
                    route: '/manage',
                },
                {
                    id: 5,
                    name: 'Copy Service ID',
                    subText: `<p>Locate the <strong>Service ID</strong> for the service you just created below your service name.</p>
                        <p>Copy and paste this for the service.id property in the <strong>hello-ziggy.properties</strong> file.</p>`,
                    route: '/config',
                },
                {
                    id: 6,
                    name: 'Enroll SDK Identity',
                    subText: `<p>From the command line in the <strong>/hello-ziggy-sdk/dist</strong> folder, run <strong>java -jar hello-ziggy.jar enroll</strong></p>`,
                    route: '/manage',
                    pause: true,
                },
                {
                    id: 7,
                    name: 'Host Custom Service',
                    subText: `<p>From the command line in the <strong>/hello-ziggy-sdk/dist</strong> folder, run <strong>java -jar hello-ziggy.jar host</strong></p>
                        <p>This will start up the simple HTTP server defined in the <strong>HelloZiggyBind.java</strong> class</p>`,
                    route: '/manage',
                    pause: true,
                },
                {
                    id: 8,
                    name: 'Access Custom Service',
                    subText: `<p>From the command line in the <strong>/hello-ziggy-sdk/dist</strong> folder, run <strong>java -jar hello-ziggy.jar access</strong></p>
                        <p>This will run the simple HTTP client in the <strong>HelloZiggyDial.java</strong> class</p>`,
                    route: '/manage',
                    pause: true,
                },
            ],
            hints: [
                {
                    event: 'sdk-identity',
                    selector: '.manageIdentitites .addButton .button',
                    description:
                        'Click the "Add Identity" button to create a new Identity. We\'ll use this later in the "App Embeded" demo.',
                    onBeforeStart: () => {
                        $('.manageIdentitites .addButton .button').off('click');
                        $('.manageIdentitites .addButton .button').click((event) => {
                            this.modalOpen = true;
                            delay(() => {
                                this.nextStep();
                            }, 100);
                        });
                    },
                },
                {
                    event: 'sdk-identity-name',
                    selector: '.manageIdentitites .actionBoxRow:nth-child(2) input',
                    description: 'Give your identity a name. For the SDK demo let\'s use "hello-ziggy-ident"',
                    onBeforeStart: () => {
                        delay(() => {
                            $('.new-app-name input').first().focus();
                        }, 500);
                    },
                },
                {
                    event: 'sdk-identity-name',
                    selector: '.actionBoxRow:nth-child(2) .inner',
                    description: 'Click the "Check" button to save the identity',
                },
                {
                    event: 'download-token',
                    selector: '.manageIdentitites .tokenBtn-0',
                    description:
                        'Click here to download your enrolment token. Copy this file into the /hello-ziggy-sdk/dist/identities folder.',
                },
                {
                    event: 'save-intercept-config',
                    selector: '.serviceFullName',
                    description:
                        'Copy the Service ID to your clipboard and save it. You\'ll need this later when configuring the "Hello Ziggy" OpenZiti SDK app',
                },
            ],
        },
    ];

    constructor(
        private router: Router,
        public dialogForm: MatDialog,
        public zedsSvc: ZedsService,
        private activatedRoute: ActivatedRoute
    ) {
        window['enjoyhint'] = this.enjoyhint_instance;
    }

    ngOnInit() {
        this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: Params) => {
            const startTour = params['startTour'] === 'true';
            if (startTour) {
                delay(() => {
                    this.startTour();
                }, 300);
            }
        });
    }

    startTour() {
        const data = {
            title: 'Prerequisites',
            appendId: 'ZEDS',
            subtitle: 'Before we begin, please make sure you have the following downloaded/installed on your device:',
            bulletList: [
                {
                    name: 'Ziti Desktop Edge client',
                    src: 'https://netfoundry.io/resources/support/downloads/networkversion7/#zititunnelers',
                },
            ],
            icon: 'Confirm',
            action: 'Done',
        };
        this.dialogRef = this.dialogForm.open(ConfirmComponent, {
            data: data,
            height: '340px',
            width: '600px',
            autoFocus: false,
        });
        this.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.openStep(this.steps[0]);
            }
        });
    }

    prevStep() {
        if (this.prevDisabled) {
            return;
        }
        const prevStepIndex = this.currentStep.id - 1;
        if (isEmpty(this.currentSubStep)) {
            this.currentSubStep = cloneDeep(this.currentStep.subSteps[0]);
        } else if (this.currentSubStep.id === 0) {
            if (prevStepIndex < 0) {
                this.showRootMenu = true;
                this.showSubMenu = false;
                return;
            }
            this.currentStep = cloneDeep(this.steps[prevStepIndex]);
        } else {
            this.currentSubStep = cloneDeep(this.currentStep.subSteps[this.currentSubStep.id - 1]);
        }
        this.currentStep.subSteps.forEach((step) => {
            step.open = false;
        });
        this.currentStep.subSteps[this.currentSubStep.id].open = true;
        //this.prevDisabled = this.currentSubStep.id === 0;
        const route = this.currentSubStep.route || this.currentStep.route;

        if (this.router.url?.indexOf(route) < 0) {
            this.router.navigate([route], { queryParams: { showTourGuide: 'true' } });
        }

        if ((this.currentStep.pause || this.currentSubStep.pause) && !this.tourPaused) {
            this.tourPaused = true;
            this.modalOpen = false;
        } else if (this.tourPaused) {
            delay(() => {
                $('.enjoyhint_prev_btn').click();
            }, 100);
        } else {
            delay(() => {
                $('.enjoyhint_prev_btn').click();
            }, 100);
        }
    }

    nextStep(event?) {
        if (this.nextDisabled) {
            console.log('test321');
            return;
        }
        if (this.currentSubStep?.validation && !this.currentSubStep?.validation()) {
            console.log('test654');
            if (event && event.preventDefault) {
                event.preventDefault();
            }
            return;
        }
        let nextStepIndex = 0;
        const holdCount = this.currentSubStep?.holdCount || 0;
        if (this.currentHoldCount < holdCount) {
            delay(() => {
                this.enjoyhint_instance.trigger('next');
            }, 100);
            this.currentHoldCount++;
            return;
        }
        this.currentHoldCount = 0;
        if (!isEmpty(this.currentStep)) {
            console.log('test731');
            nextStepIndex = this.currentStep.id + 1;
        } else {
            console.log('test098');
            this.startTour();
        }
        if (nextStepIndex > this.steps.length) {
            console.log('test123');
            return;
        }
        if (isEmpty(this.currentSubStep)) {
            this.currentSubStep = cloneDeep(this.currentStep.subSteps[0]);
        } else if (this.currentSubStep.id < this.currentStep.subSteps.length - 1) {
            this.currentSubStep = cloneDeep(this.currentStep.subSteps[this.currentSubStep.id + 1]);
        } else {
            this.currentStep = cloneDeep(this.steps[nextStepIndex]);
            this.currentSubStep = cloneDeep(this.currentStep.subSteps[0]);
            const route = this.currentStep.route || this.currentSubStep.route;
            if (this.router.url?.indexOf(route) < 0) {
                this.router.navigate([route], { queryParams: { showTourGuide: 'true' } });
            }
            this.startNewEnjoyHint();
            this.showRootMenu = false;
            this.showSubMenu = true;
            //this.prevDisabled = this.currentSubStep.id === 0;
            this.currentStep.subSteps.forEach((step) => {
                step.open = false;
            });
            this.currentStep.subSteps[this.currentSubStep.id].open = true;
            if (this.currentSubStep.action) {
                this.currentSubStep.action();
            }
            return;
        }
        this.currentStep.subSteps.forEach((step) => {
            step.open = false;
        });
        this.currentStep.subSteps[this.currentSubStep.id].open = true;
        //this.prevDisabled = this.currentSubStep.id === 0;
        this.showRootMenu = false;
        this.showSubMenu = true;

        const route = this.currentStep.route || this.currentSubStep.route;
        if (this.router.url?.indexOf(route) < 0) {
            this.router.navigate([route], { queryParams: { showTourGuide: 'true' } });
        }

        if (this.currentStep.pause || this.currentSubStep.pause) {
            if (!this.tourPaused) {
                if (this.currentSubStep.action) {
                    this.currentSubStep.action();
                } else {
                    this.tourPaused = true;
                    this.modalOpen = false;
                }
            }
        } else if (this.tourPaused) {
            if (!this.enjoyhintStarted && !(this.currentStep.pause || this.currentSubStep.pause)) {
                this.enjoyhint_instance.run();
                this.enjoyhintStarted = true;
                delay(() => {
                    this.modalOpen = true;
                }, 300);
            } else if (this.enjoyhintStarted && !(this.currentStep.pause || this.currentSubStep.pause)) {
                delay(() => {
                    this.enjoyhint_instance.trigger('next');
                }, 100);
            }
        } else {
            if (!this.enjoyhintStarted) {
                this.enjoyhint_instance.run();
                this.enjoyhintStarted = true;
            } else {
                delay(() => {
                    this.enjoyhint_instance.trigger('next');
                }, 100);
            }
        }
    }

    returnHome() {
        this.showRootMenu = true;
        this.showSubMenu = false;
        this.modalOpen = false;
        this.enjoyhint_instance?.trigger('skip');
        return;
    }

    toggleSubStep(step) {
        step.open = !step.open;
    }

    startNewEnjoyHint() {
        this.enjoyhint_instance = new EnjoyHint(this.enjoy_hint_options);
        this.enjoyhint_instance.set(this.currentStep.hints);
        if (!this.currentSubStep.pause) {
            this.enjoyhint_instance.run();
            this.enjoyhintStarted = true;
            delay(() => {
                this.modalOpen = true;
            }, 300);
        } else {
            this.enjoyhintStarted = false;
            this.modalOpen = false;
            if (this.currentSubStep.action) {
                this.currentSubStep.action();
            }
        }
        $('.build-app-bar .button').click((event) => {
            delay(() => {
                this.nextStep();
            }, 100);
        });
        $('.editBtn-0 .inner').click((event) => {
            delay(() => {
                this.nextStep();
            }, 100);
        });
    }

    openStep(selectedStep) {
        this.enjoyhint_instance = new EnjoyHint(this.enjoy_hint_options);
        this.currentStep = cloneDeep(selectedStep);
        this.currentSubStep = cloneDeep(this.currentStep.subSteps[0]);
        this.currentStep.subSteps[0].open = true;
        this.showRootMenu = false;
        this.showSubMenu = true;
        //set script config
        this.enjoyhint_instance.set(this.currentStep.hints);

        const route = this.currentStep.route || this.currentSubStep.route;
        if (this.router.url?.indexOf(route) < 0) {
            this.router.navigate([route], { queryParams: { showTourGuide: 'true' } });
        }
        //run Enjoyhint script
        if (this.currentSubStep.pause) {
            if (this.currentSubStep.action) {
                this.currentSubStep.action();
            }
        } else {
            this.enjoyhint_instance.run();
            this.enjoyhintStarted = true;
            for (let i = 1; i < this.currentStep.stepStart; i++) {
                this.enjoyhint_instance.trigger('next');
            }
            delay(() => {
                this.modalOpen = true;
            }, 300);
        }

        delay(() => {
            $('input').off('keydown');
            $('input').keydown((event) => {
                console.log('asdsaf');
                if (event.keyCode === 13) {
                    this.nextStep(event);
                }
                if (event.keyCode === 9 && this.modalOpen) {
                    event.preventDefault();
                }
            });
            $('.build-app-bar .button').off('click');
            $('.build-app-bar .button').click((event) => {
                delay(() => {
                    this.nextStep();
                }, 100);
            });
        }, 300);
    }

    stepEnd(event) {
        this.modalOpen = false;
        console.log('step end');
    }

    restartStep() {
        this.enjoyhintStarted = false;
        this.openStep(this.currentStep);
    }

    _showInstallerConfirm() {
        if (this.actionOpen) {
            return;
        }
        this.modalOpen = false;
        this.actionOpen = true;
        const data = {
            title: 'Install ZITI Tunneler',
            appendId: 'ZEDS',
            subtitle:
                'If you haven\'t already downloaded/installed a Ziti Tunneler you can do so via the link below. When you\'re ready click "Done".',
            bulletList: [
                {
                    name: 'Download a Ziti Edge Tunneler here',
                    src: 'https://netfoundry.io/resources/support/downloads/networkversion7/#zititunnelers',
                },
            ],
            icon: 'Confirm',
            action: 'Done',
        };
        this.dialogRef = this.dialogForm.open(ConfirmComponent, {
            data: data,
            height: '340px',
            width: '600px',
            autoFocus: false,
        });
        this.dialogRef.afterClosed().subscribe((result) => {
            this.actionOpen = false;
            this.modalOpen = true;
            this.nextStep();
        });
    }
}
