import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedGrowlerModule } from '@netfoundry-ui/shared/growler';

import { UiJsonViewModule } from '@netfoundry-ui/ui/json-view';
import { CollectorBoxComponent } from './collector-box/collector-box.component';
import { PreconfigBoxComponent } from './preconfig-box/preconfig-box.component';
import { ManageActionBoxComponent } from './manage-action-box/manage-action-box.component';
import { ServiceButtonComponent } from './preconfig-box/service-button/service-button.component';
import { ActionButtonComponent } from './manage-action-box/action-button/action-button.component';
import { CustomConfigBoxComponent } from './custom-config-box/custom-config-box.component';
import { ConfigEditBoxComponent } from './config-edit-box/config-edit-box.component';
import { ConfigEditHeaderComponent } from './config-edit-box/config-edit-header/config-edit-header.component';
import { ConfigEditFooterComponent } from './config-edit-box/config-edit-footer/config-edit-footer.component';
import { ConfigTypeEditorComponent } from './config-type-editor/config-type-editor.component';
import { ConfigTypeEditorHeaderComponent } from './config-type-editor/config-type-editor-header/config-type-editor-header.component';
import { ConfigTypeEditorFooterComponent } from './config-type-editor/config-type-editor-footer/config-type-editor-footer.component';
import { SampleServiceComponent } from './sample-service/sample-service.component';
import { SampleServiceHeaderComponent } from './sample-service/sample-service-header/sample-service-header.component';
import { TourGuideComponent } from './tour-guide/tour-guide.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UiJsonViewModule,
        HttpClientModule,
        MatDialogModule,
        MatTooltipModule,
        SharedGrowlerModule,
    ],
    declarations: [
        CollectorBoxComponent,
        PreconfigBoxComponent,
        ManageActionBoxComponent,
        ServiceButtonComponent,
        ActionButtonComponent,
        CustomConfigBoxComponent,
        ConfigEditBoxComponent,
        ConfigEditHeaderComponent,
        ConfigEditFooterComponent,
        ConfigTypeEditorComponent,
        ConfigTypeEditorHeaderComponent,
        ConfigTypeEditorFooterComponent,
        SampleServiceComponent,
        SampleServiceHeaderComponent,
        TourGuideComponent,
    ],
    exports: [
        CollectorBoxComponent,
        PreconfigBoxComponent,
        ManageActionBoxComponent,
        CustomConfigBoxComponent,
        ConfigEditBoxComponent,
        ConfigEditHeaderComponent,
        ConfigEditFooterComponent,
        TourGuideComponent,
    ],
})
export class FeatureZedsModule {}
