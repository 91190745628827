import { Injectable } from '@angular/core';
import { Network, NetworkGroup, NetworkV2, Tenant } from '@netfoundry-ui/shared/model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
    UpdateCurrentNetwork,
    UpdateCurrentNetworkGroup,
    UpdateCurrentOrganization,
    UpdateIsLogin,
} from '../actions/simple.actions';

export class SimpleStateModel {
    isLoggedIn!: boolean;
    currentOrganization!: Tenant;
    currentNetworkGroup!: NetworkGroup;
    currentNetwork!: Network | NetworkV2;
}

@State<SimpleStateModel>({
    name: 'simple',
    defaults: {
        isLoggedIn: false,
        currentOrganization: new Tenant({}),
        currentNetworkGroup: new NetworkGroup({}),
        currentNetwork: new NetworkV2(),
    },
})
@Injectable({
    providedIn: 'root',
})
export class SimpleState {
    @Selector()
    static getIsLoggedIn(state: SimpleStateModel) {
        return state.isLoggedIn;
    }

    @Selector()
    static getIsCurrentOrganization(state: SimpleStateModel) {
        return state.currentOrganization;
    }

    @Selector()
    static getCurrentNetworkGroup(state: SimpleStateModel) {
        return state.currentNetworkGroup;
    }

    @Selector()
    static getCurrentNetwork(state: SimpleStateModel) {
        return state.currentNetwork;
    }

    @Action(UpdateIsLogin)
    updateLogin({ patchState }: StateContext<SimpleStateModel>, { payload }: UpdateIsLogin) {
        patchState({
            isLoggedIn: payload,
        });
    }

    @Action(UpdateCurrentOrganization)
    updateCurrentOrganization({ patchState }: StateContext<SimpleStateModel>, { payload }: UpdateCurrentOrganization) {
        patchState({
            currentOrganization: payload,
        });
    }

    @Action(UpdateCurrentNetworkGroup)
    updateCurrentNetworkGroup({ patchState }: StateContext<SimpleStateModel>, { payload }: UpdateCurrentNetworkGroup) {
        patchState({
            currentNetworkGroup: payload,
        });
    }

    @Action(UpdateCurrentNetwork)
    updateCurrentNetwork({ patchState }: StateContext<SimpleStateModel>, { payload }: UpdateCurrentNetwork) {
        patchState({
            currentNetwork: payload,
        });
    }
}
