import { Component } from '@angular/core';
import { Auth0Service } from '@netfoundry-ui/shared/authorization';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-app-menu',
    templateUrl: './app-menu.component.html',
    styleUrls: ['./app-menu.component.scss'],
})
export class AppMenuComponent {
    showTourGuide = false;

    constructor(private auth: Auth0Service, private router: Router, private route: ActivatedRoute) {}

    logout() {
        this.auth.logout();
    }

    showGettingStarted() {
        this.router.navigate(['/getting-started']);
    }

    openTourGuide() {
        const queryParams: any = {};
        this.showTourGuide = !this.showTourGuide;
        if (this.showTourGuide) {
            queryParams.showTourGuide = 'true';
        }

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: queryParams,
        });
    }
}
