import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-config-edit-header',
    templateUrl: './config-edit-header.component.html',
    styleUrls: ['./config-edit-header.component.scss'],
})
export class ConfigEditHeaderComponent {
    @Input() configName = '';
    @Input() fullScreen = false;
    @Output() delete = new EventEmitter<void>();

    constructor() {}

    deleteRequested() {
        this.delete.emit();
    }
}
