<div class="control-box">
    <img [src]="iconSrc" class="collectorIcon" /><a class="learnmore" href="{{ learnmore }}" target="_blank"
        >Learn More</a
    >
    <div [innerHTML]="title" class="title"></div>
    <div [innerHTML]="subtitle" class="subtitle mb8"></div>
    <div *ngIf="_data && _data.length > 0" [ngClass]="{ long: !isShowStatus }" class="actionBoxRow">
        <span>Name</span>
        <span *ngIf="isShowStatus">Status</span>
        <span *ngIf="isEdit">Edit</span>
        <span *ngIf="isToken">Token</span>
        <span *ngIf="isDelete">Delete</span>
    </div>
    <div class="collectors">
        <div
            *ngFor="let obj of _data; let idx = index; trackBy: indexTrackBy"
            [ngClass]="{ long: !isShowStatus }"
            class="actionBoxRow"
        >
            <div class="input-container">
                <input
                    [(ngModel)]="obj.name"
                    [disabled]="obj.createdAt"
                    [placeholder]="placeholderText.replace('{i}', idx + 1)"
                    type="text"
                />
                <img
                    (click)="copyFullName(obj)"
                    *ngIf="showCopyName && obj.fullName"
                    class="copyicon"
                    src="/assets/svgs/copy.svg"
                    title="Click to copy full service id for use in configs and sdks [{{ obj.fullName }}]"
                />
            </div>

            <div
                *ngIf="obj.createdAt && isShowStatus"
                [ngStyle]="{ color: obj.online ? '#00DC5A' : '#e0e6e9' }"
                class="status"
                title="Identity Status"
            >
                &#9679;
            </div>
            <app-action-button
                (selected)="create(obj)"
                *ngIf="!obj.createdAt && isCreate"
                [loading]="obj.action === 'creating'"
                icon="./assets/images/check.svg"
                name="createBtn"
                title="Create"
            ></app-action-button>

            <app-action-button
                (selected)="token(obj)"
                *ngIf="obj.createdAt && isToken"
                [ngClass]="{ 'no-token': !obj.jwtExpiresAt }"
                class="tokenBtn-{{ idx }}"
                icon="./assets/images/Token.svg"
                name="tokenBtn"
                title="{{ !obj.jwtExpiresAt ? 'Identity token has already been consumed' : '' }}"
                title="Download your registration token"
            ></app-action-button>
            <app-action-button
                (selected)="edit(obj)"
                *ngIf="obj.createdAt && isEdit"
                class="editBtn-{{ idx }}"
                icon="./assets/images/EditIcon.svg"
                name="editBtn"
                title="Edit this service"
            ></app-action-button>
            <app-action-button
                (selected)="delete(obj)"
                *ngIf="obj.createdAt && isDelete"
                [loading]="obj.action === 'deleting'"
                icon="./assets/images/Delete.svg"
                name="deleteBtn"
                title="Delete"
            ></app-action-button>
        </div>
    </div>
    <div *ngIf="allowAdd" class="addButton mt16">
        <div
            (click)="onAdd()"
            [ngClass]="{ disabled: noAppSelected }"
            [title]="noAppSelected ? 'Please select an application before adding resources' : ''"
            class="button"
        >
            Add Another
        </div>
    </div>
</div>
