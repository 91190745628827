<div class="control-box">
    <span *ngIf="stepNbr > 0" class="step">{{ stepNbr }}</span>
    <img [src]="titleIcon" class="collectorIcon" />
    <div [innerHTML]="title" class="title"></div>
    <div [innerHTML]="subtitle" class="subtitle"></div>
    <div class="collectors">
        <div *ngFor="let r of data; let idx = index; trackBy: indexTrackBy">
            <input
                (ngModelChange)="onChange()"
                [(ngModel)]="data[idx]"
                [ngClass]="{ invalid: validationErrors && validationErrors.indexes[idx] }"
                [placeholder]="placeholderText.replace('{i}', idx + 1)"
                [title]="validationErrors && validationErrors.indexes[idx] ? validationErrors.message : ''"
                maxlength="64"
                minlength="5"
                name="input{{ idx }}"
                type="text"
            />
        </div>
    </div>
    <div *ngIf="allowAdd" class="addButton">
        <div (click)="onAdd()" class="button">Add Another</div>
    </div>
</div>
